module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"5e8b4a5a-5ad9-46b0-b2a0-1e7553e35ed9","guideId":"304","siteUrl":"https://www.trailsofvastmanland.se","lang":"sv","typesCategories":{"mountainbiking":["16","31"],"hiking":["33"],"paddling":["37"],"outdoors":["30"]},"typesCategoriesIncluded":{"mountainbiking":["16","31"],"hiking":["33"],"paddling":["37"],"outdoors":["30"]},"imagePlaceholderColor":"#bb8b6f","templates":{"type":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/type.js","site":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/site.js","page":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/page.js","articles":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/articles.js","articlesPost":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/articles-post.js","news":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/news.js","newsPost":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/news-post.js","events":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/events.js","eventsPost":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/events-post.js","search":"/build/91cb1298-40ea-43d9-a0b7-a06990b29f95/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Trails of Västmanland","short_name":"Trails of Västmanland","description":"Utvalda leder för dig som vill uppleva Västmanland","theme_color":"#005096","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
